.testimonial_sections{
    padding-top: 50px;
}
.reviewSlider{
    width: 100%;
    height: 100%;
    display: inline-block;
    margin-top: 40px;
    padding-bottom: 60px;
}

.review_container{
    background: #FFFFFF;
box-shadow: 2px 4px 4px 5px #224DA1;
border-radius: 30px;
height: 300px;
width: 400px;
position: relative;
display: flex;
justify-content: center;
flex-direction: column;

}
.review_container i{
position: absolute;
top:-27px;
left:10px;
font-size: 5rem;
color: #224DA1;
z-index: 10000;
}
.review_container img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    top:30px;
    left: 50%;
    transform: translateX(-50%);

}
.review_container h4{
    font-weight: 400;
font-size: 1.9rem;
margin-top: 80px;

color: rgba(0, 0, 0, 0.88);
}
.review_container p{
    font-weight: 400;
font-size: 1.5rem;
padding: 10px;

color: rgba(0, 0, 0, 0.78);
}


/* ======================mobile responsive========================= */
@media (max-width:576px) {

    .review_container{
        background: #FFFFFF;
    box-shadow: 2px 4px 4px 5px #224DA1;
    border-radius: 30px;
    height: 300px;
    width: 254px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 -26px;
    }
.review_container i {
    position: absolute;
    top: -24px;
    left: 23px;
    font-size: 5rem;
    color: rgb(34, 77, 161);
    z-index: 10000;
}
}

@media (max-width:376px) {

    .review_container{
        background: #FFFFFF;
    box-shadow: 2px 4px 4px 5px #224DA1;
    border-radius: 30px;
    height: 300px;
    width: 204px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    }
    .review_container h4 {
        margin-top: 115px;
    }


}
