.offer_section{
    width:100%;
    height: 100%;
   margin-top: 105px;
    margin-bottom: 74px;
}
.Offer_card{
position: relative;

border-radius: 10px;
width: 318px;
height: 251px;

}
.Offer_card img{
width: 100%;
height: 100%;
border-radius: 10px;
background-position: center;
background-size: cover;
border: 3px solid #0A2A6A;
transition: all 1s ease-in-out;
}
.Offer_card img:hover{
    transform: scale(1.05);
   
}
.Offer_card:hover>.class_indstris_h{
  
    right: 4px;
}

/* .Offer_card::after{
    position: absolute;
  content: " ";
  top: 0;
  left: 7px;
  width: 300px;
  height: 251px;
  z-index: 0;
  border-radius: 30px;
  background-color:rgba(0, 0, 0, 0.4);
  
} */

.content_offer{
    position: absolute;
    top:0;
    border-radius: 10px;
    text-align: center;
    width: 302px;
    height: 100%;
    z-index: 100;
    background: rgb(0,0,0,0.6);
    opacity: 1;
transition: 1s;
display: grid;
place-items: center;
}
.content_offer:hover{
    opacity: 0;
background: rgb(0,0,0,0.6);
width: 302px;
    height: 100%;
}
.content_offer h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;
line-height: 39px;
/* identical to box height */


color: #FFFFFF;
}
.container_class{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center start;
}
.content_offer p{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;
line-height: 39px;

color: #00B274;

}
.content_offer div{
width: 270px;
}
@media (max-width: 576px) {
    .Offer_card{
     
    }
    .content_offer:hover{
     

        
    }
}