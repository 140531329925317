

.gallery{
    background: #FFFFFF;
border: 1px solid #0A2A6A;
/* box-shadow: 4px 4px 4px 4px rgba(34, 77, 161, 0.85); */
box-shadow:  rgba(34, 77, 161, 0.85) 0px 0px 16px;
border-radius: 10px;
position: relative;
margin-top: 50px;
}
.gallery h4{
    position: absolute;
    top: -24px;
    left: 22px;
    background: #0A2A6A;
    color: #FFFFFF;
    padding: 10px;
    font-size: 2rem;
    border-radius: 10px;
    
}
.gallery .Img_class{
    width: 300px;
    height: 300px;
    margin: 10px;

}
.gallery .Img_class img{
    width: 300px;
    height: 300px;
    background: rgba(248, 248, 255, 0.81);
border: 1px solid #0A2A6A;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}
.nav-tabs .nav-link{
    font-size: 1.6rem;
    color: #FFFFFF;
}
.nav-tabs li{
    background-color: #0A2A6A;
}
.nav-tabs {
    margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
    
}