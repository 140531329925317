.main_products{
  
  width: 300px;
  height: 300px;
  border: 5px solid #0A2A6A;
  border-radius: 10px;
  margin-top: 20px;
  border-bottom: 37px solid #0A2A6A;
  transition: all 1s ease-in-out;
}
.main_products img{
    width: 100%;
    height: 100%;
}
.main_products img:hover{
transform: scale(1.1);
border-radius: 10px;
}
.main_products h3{
    color: #fff;
    margin-top: 9px;
}
.product_type{
    display: flex;
    align-items: center;
    justify-content: center;
}
.option_class{
    display: flex;
    align-items: center;
    justify-content: center;
}
.duplex_table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  .duplex_table_div{
    overflow-x: auto;

  }
  th, td {
    text-align: left;
    padding: 8px;
    /* width: 150px; */
    /* height: 30px; */
    /* white-space: nowrap; */
    width: auto;
  }
  
  tr:nth-child(even){background-color: #f2f2f2}

  .brandimg{
    gap: 10px;
    justify-content: center;
    width: fit-content;
  }
  .WeldingBrad{
    padding: 0 50px;
  }
  .brandimg img{
    width: 200px;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #0A2A6A;
  }
  .welding_cons{
    font-size: 1.5rem;
    font-weight: 500;
  }
  .fastener_img{
    height: 400px;
    width: 98%
  }
  .class_indstris_h{
    /* position: absolute; */
    position: absolute;
    top: 8px;
    right: 9px;
    background-color: #0A2A6A;
    padding: 5px;
    color: #fff;
    border-radius: 8px 0px 0px 8px;
  }
  .slider-card-title{
    font-size: 1.5rem;
  }
  #main-slider-container{
    width: 100%;
    margin: 44px 0;
  }
  .slider-card-description {
    opacity: 0.5;
    font-size: 13px;

}
/* #main-slider-container .slider-icon.left {
  right: 0px !important;
}
.slider-icon.left {

  right: 0!important;
  transform: rotate(180deg);
}
#main-slider-container .slider-icon.right {

  left:0px !important;
  transform: 180deg;
  transform: rotate(180deg);
} */
  @media (max-width:576px) {

    .slider-card {
      width: 250px;
      height: 300px;
    }
    .slider-card-description {
      opacity: 0.5;
      font-size: 10px;

  }
    .fastener_img{
      height: auto;
    width: 95%

    }
    .WeldingBrad{
        padding: 0 30px
      }
      
  }