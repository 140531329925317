* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
}
body{
  background-color: #F8F8FF;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

html::-webkit-scrollbar {
  width: 1rem;
  overscroll-behavior: contain;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background: var(--main_primary_color);
}
section{
/* padding-top: 50px;
padding-bottom: 50px; */


}

.cssload-preloader {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
}

.cssload-preloader > .cssload-preloader-box {
	position: absolute;
	height: 29px;
	top: 50%;
	left: 50%;
	margin: -15px 0 0 -146px;
	perspective: 195px;
		-o-perspective: 195px;
		-ms-perspective: 195px;
		-webkit-perspective: 195px;
		-moz-perspective: 195px;
}

.cssload-preloader .cssload-preloader-box > div {
	position: relative;
	width: 29px;
	height: 29px;
	background: rgb(204,204,204);
	float: left;
	text-align: center;
	line-height: 29px;
	font-family: Verdana;
	font-size: 19px;
	color: rgb(255,255,255);
}.cssload-preloader .cssload-preloader-box > div:nth-child(1) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 0ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 0ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 0ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 0ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 0ms infinite alternate;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(2) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 86.25ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 86.25ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 86.25ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 86.25ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 86.25ms infinite alternate;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(3) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 172.5ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 172.5ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 172.5ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 172.5ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 172.5ms infinite alternate;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(4) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 258.75ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 258.75ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 258.75ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 258.75ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 258.75ms infinite alternate;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(5) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 345ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 345ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 345ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 345ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 345ms infinite alternate;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(6) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 431.25ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 431.25ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 431.25ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 431.25ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 431.25ms infinite alternate;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(7) {
	background: #0A2A6A;
	margin-right: 15px;
	animation: cssload-movement 690ms ease 517.5ms infinite alternate;
		-o-animation: cssload-movement 690ms ease 517.5ms infinite alternate;
		-ms-animation: cssload-movement 690ms ease 517.5ms infinite alternate;
		-webkit-animation: cssload-movement 690ms ease 517.5ms infinite alternate;
		-moz-animation: cssload-movement 690ms ease 517.5ms infinite alternate;
}


@keyframes cssload-movement {
	from {
		transform: scale(1.0) translateY(0px) rotateX(0deg);
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
	to {
		transform: scale(1.5) translateY(-24px) rotateX(45deg);
		box-shadow: 0 24px 39px #0A2A6A;
		background: #0A2A6A;
	}
}

@-o-keyframes cssload-movement {
	from {
		-o-transform: scale(1.0) translateY(0px) rotateX(0deg);
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
	to {
		-o-transform: scale(1.5) translateY(-24px) rotateX(45deg);
		box-shadow: 0 24px 39px #0A2A6A;
		background: #0A2A6A;
	}
}

@-ms-keyframes cssload-movement {
	from {
		-ms-transform: scale(1.0) translateY(0px) rotateX(0deg);
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
	to {
		-ms-transform: scale(1.5) translateY(-24px) rotateX(45deg);
		box-shadow: 0 24px 39px #0A2A6A;
		background: #0A2A6A;
	}
}

@-webkit-keyframes cssload-movement {
	from {
		-webkit-transform: scale(1.0) translateY(0px) rotateX(0deg);
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
	to {
		-webkit-transform: scale(1.5) translateY(-24px) rotateX(45deg);
		box-shadow: 0 24px 39px #0A2A6A;
		background: #0A2A6A;
	}
}

@-moz-keyframes cssload-movement {
	from {
		-moz-transform: scale(1.0) translateY(0px) rotateX(0deg);
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
	to {
		-moz-transform: scale(1.5) translateY(-24px) rotateX(45deg);
		box-shadow: 0 24px 39px #0A2A6A;
		background: #0A2A6A;
	}
}
#loader{
	background: rgba(255,255,255,0.8);
	width: 100%;
	height: 100vh;
	position: fixed;
	top:0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	
  }

  /* Loader styling */
  
  #loader img{
	display: block;
	margin: 20px auto;
	width: 300px;
	height: auto;
	max-height: 300px;
	object-fit: cover;
  }