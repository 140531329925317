.slideImg{
    width: 100%;
   
  
}

@keyframes rotate {
  /* 0% {
    transform: rotate(-45deg)
  }
  100% {
    transform: rotate(45deg)
  } */
  from { transform: rotate(0deg) }
to { transform: rotate(360deg) }
}

/* CSS */
.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 150px;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 10px 20px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}
.img_fillter{
  filter:  brightness(50%);
}
@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 196px;
   
  }
}
.Slide_container{
    margin-top: 64px;
    position: relative;
    top:0;
   
}
.carousel-inner {
    position: relative;
    width: 100%;
    background-size: contain;
    background-position: center;
   
}
.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 89vh;
}
.carousel .slide .Home_page_heading img{
  height: 400px;
  width: 400px;
    }
.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
    margin-left: 216px;
    transform: translate(288px, -104px);
    display: none;
}

.Home_page_heading{
    width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50% ,-50%);
}
.Home_page_heading .content{
    text-align: left;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Home_page_heading h3{
    font-family: 'Inter';
    font-family:' Kumar One Outline';
font-weight: 700;
font-size: 3.2rem;
line-height: 63px;

color: #FFFFFF;
/* text-shadow: -16px 6px 15px #1d5dbd; */
text-shadow: 
0 0 10px #1d5dbd,
0 0 20px rgb(39, 14, 14),
0 0 40px rgb(54, 19, 70),
0 0 80px  red;
will-change: filter, color;
filter: saturate(60%);
animation: flicker steps(100) 1s 1s infinite;

}
.Home_page_heading p{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 1.6rem;
text-shadow: -16px 6px 15px #000;
text-shadow: 
0 0 10px #1d5dbd,
0 0 20px rgb(39, 14, 14),
0 0 40px rgb(54, 19, 70),
0 0 80px #000;
will-change: filter, color;
filter: saturate(60%);
animation: flicker steps(100) 1s 1s infinite;
color: #FFFFFF;

}
.carousel .slide .Home_page_heading img{
height: 400px;
animation: rotate 30s infinite;
}
@media (max-width:576px) {
    html {
      font-size: 50%;
    }
    .about .row .content h3 {
      font-size: 2.5rem;
    }
    .carousel .slide .Home_page_heading img{
        height: 100px;

        width: 100px;
        }
        .carousel .slide img {
            width: 100%;
            vertical-align: top;
            border: 0;
            height: 250px;
          
        }
        .Home_page_heading h3{
            font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6rem;
        
        color: #FFFFFF;
        
        }
        .Home_page_heading p{
            font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        
        
        color: #FFFFFF;
        
        }
    
  }
  .button_home{
    padding: 10px 20px;
    border: none;
    outline: none;
    background-color: #224DA1;
    color: #FFFFFF;
    width: 150px;
    font-size: 1.5rem;
    border-radius: 7px;
    font-weight: 600;
  }

  @media (max-width:576px) {
  .button_home {
    padding: 0 0;
    border: none;
    outline: none;
    background-color: #224DA1;
    color: #FFFFFF;
    width: 64px;
    height: 19px;
    font-size: 1rem;
    border-radius: 7px;
    font-weight: 600;
  }
}