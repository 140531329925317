#products_sections{
    
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
    
}
.poducts_heading{
    

    padding-top: 30px;
    text-align: center;
}
.poducts_heading h2{
    font-weight: 400;
font-size: 3rem;
line-height: 20px;

color: #000000;
}
.poducts_heading h4{
    font-weight: 400;
font-size:1.5rem;
line-height: 19px;

color: #838280;
}
.products_cart1{
    width: 389px;
height: 300px;
    border: 1px solid  #000;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 30px;
margin: 20px;
margin-left: 60px;
background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../../Constants/Images/productsImg/procts1.jpg');
position: relative;
}
.products_cart2{
    width: 389px;
height: 300px;
    border: 1px solid  #000;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 30px;
margin: 20px;
margin-left: 60px;
background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../../Constants/Images/productsImg/procts2.jpg');
position: relative;
}

.products_cart3{
    width: 389px;
height: 300px;
    border: 1px solid  #000;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 30px;
margin: 20px;
margin-left: 60px;
background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../../Constants/Images/productsImg/procts5.jpg');
position: relative;
}

.products_cart4{
    width: 389px;
height: 300px;
    border: 1px solid  #000;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 30px;
margin: 20px;
margin-left: 60px;
background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../../Constants/Images/productsImg/procts3.jpg');
position: relative;

}


.Slider_controler{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.Slider_controler button{
    margin: 0 2rem;
    padding: 10px;
    font-size: 1rem;
    background-color: var(--main_primary_color);
}

.product_heading{
position: absolute;
top: 0;
width: 100%;
height: 30px;
background-color: #0A2A6A;
display: flex;
align-items: center;
justify-content: center;
border-top-left-radius: 30px;
border-top-right-radius: 30px;
color: #fff;
}
.heading_h4{
    font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 1.3rem;
margin-left: 3rem;
/* identical to box height */
margin-top: 4rem;
text-align: left;
color: #00B274;

}
.inconel_products h5{
    font-size: 2rem;
    cursor: pointer;
    transition: all 1s ease;
}
.inconel_products h5:hover{
transform: translateX(10px);
text-decoration: underline;
}
.heading_4{
    font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 1.3rem;
margin-left: 3rem;
/* identical to box height */
margin-top: 0.5rem;
text-align: left;
color: #00B274;


}
.inconel_products{
   
    width: 80%;
    
    margin-left: 3.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 10px;

    
}
inconel_products h5 i{
    color: #fff;
}
.inconel_products h5{
    color: #E3B71B;
    font-size: 0.8rem;
}

.Titanium_Alloys_products{
    margin-top: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 3rem;
    justify-content: space-evenly;
    flex-direction: row;
  gap: 20px;
}
.Titanium_Alloys_products h5{
    color: #E3B71B;
    font-size: 1rem;
    flex-basis: 40%;
    cursor: pointer;
    transition: all 1s ease;
}
.Titanium_Alloys_products h5:hover{
transform: translateX(10px);
text-decoration: underline;
}
.productsSlider{
    display: flex;
    align-items: center;
    justify-content: center;
}
.slick-list{
    padding: 0px 13px
}
.slick-slide{
    width: 400px;
    padding-left: 83px;
}
#button_controller_next{
  display: block;
    background: #0A2A6A;
    position: absolute;
    right: 10px;
    z-index: 1000;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display:grid;
    place-items:center;
}
#button_controller_prew{
    display: block;
    background: #0A2A6A;
    position: absolute;
    left: 10px;
    z-index: 1000;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display:grid;
    place-items:center;
}

@media (max-width: 1024px) {
    .products_cart1,.products_cart2,.products_cart3,.products_cart4{
width: 300px;

    }
    

.review_container{
    width: 300px;
}
    
}

@media (max-width: 576px) {
    .products_cart1{
        width: 200px;
    }
    .products_cart2{
        width: 200px;
    }
    .products_cart3{
        width: 200px;
    }
    .products_cart4{
        width: 200px;
    }
    .testimonial_sections .slick-slide{
      
        padding:0 46px;
    }
    .products_sections  .slick-slide{
      
        padding:0 2px;
    }
    .slick-list{
       padding: 0 15px;
    }
    #button_controller_next{
        transform: translateY(161px);
    }
    #button_controller_prew{
    
        transform: translateY(161px);
    }
    .products_cart1, .products_cart2, .products_cart3, .products_cart4 {
        /* width: 300px; */
        margin-left: 10px !important;
    }
   #products_sections .slick-slide {
        /* width: 400px; */
        padding-left: 13px !important;
    }
}

.contact_btn a{
    text-decoration: none;
    color: #fff;
    padding: 12px 56px;
    border-radius: 10px;
    font-size: 1.5rem;
}
.contact_btn a:hover{
    color: #0A2A6A;
    background-color: #fff;
}
.font_size{
    font-size: 1.6rem !important;
}