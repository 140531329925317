.Pipe_Fittings{
    padding: 0 50px;
}
.Pipe_Fittings p{
  text-align: justify;
font-weight: 400;
font-size: 2rem;
/* line-height: 42px; */
text-transform: capitalize;
margin-top: 40px;


color: #000000;
}
.Pipe_Fittings img{
    width: 100%;
    height: 400px;
    margin-top: 20px;
}
.Pipe_Fittings .pipefiting_text{
 
}
.specfications_table{

    text-align: left;
    margin-top: 20px;
}
.specfications_table h3,.Grade_class h3,.category_fitings h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 2.5rem;
/* line-height: 48px; */
text-transform: capitalize;
margin-top: 50px;

color: rgba(0, 0, 0, 0.69);
border-bottom: 5px solid #0A2A6A;
width: fit-content;
}
.specfications_table table{
border:  1px solid #0A2A6A;
margin-top: 20px;
}
.specfications_table table tr td:first-child{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 48px;
    text-transform: capitalize;
    
    color: #000000c2;
    border-right:1px solid #0A2A6A;
}
.specfications_table table tr td:last-child{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1.7rem;
line-height: 44px;
text-transform: capitalize;

color: #000000b6;
}
.Grade_class{
    margin-top: 57px;
    text-align: left;
}
.Grade_class h4{
    text-align: left;
}
.box_class{
    background: #FFFFFF;
border: 5px solid #0A2A6A;
box-shadow: 0px 4px 4px #0A2A6A;
border-radius: 10px;
position: relative;
width: 390px;

}
.gap_{
    gap:20px;
    margin-top: 50px;
}
.grade_type{
    display: flex;
    flex-direction: column;
 padding-top: 20px;

    
}
.grade_type span{
    font-weight: 400;
font-size: 1.5rem;
line-height: 29px;
text-transform: capitalize;
text-align: left;

color: #0A2A6A;
padding: 5px;
}
.grade_type span i{
    color: #E3B71B;
    font-size: 14px;
}
.side_heading {
    background: #E3B71B;
border-radius: 10px;
position: absolute;
top: -24px;

left: 50%;
transform: translateX(-50%);


width: fit-content;
padding: 0 10px;

}
.side_heading h4{
    /* writing-mode: vertical-lr;  */
    font-weight: 600;
font-size: 1.5rem;
line-height: 36px;
text-transform: capitalize;
/* padding: 5px; */
color: #0A2A6A;
white-space: nowrap;

}
.category_fitings{
    text-align: left;
    margin-top: 50px;
}
.Img_butt{
    margin-top: 30px;
    text-align: center;
    justify-content: center;
}
.Img_butt h4{
font-size: 2rem;
/* position: absolute; */
/* background-color: #0A2A6A;
color: #FFFFFF; */
/* top: 10px;
left: 5px; */
padding: 10px;
/* border-radius: 10px; */
}
.Img_butt  div{
    width: 250px;
}
.Img_butt  div img{
border: 2px solid #0A2A6A;
width: 250px;
height: 200px;
border-radius: 10px;
}
.specfications_table table tr td{
width: auto !important;
height: auto !important;
white-space:normal;
}
@media (max-width:576px) {

    .gap_ {
        gap: 50px;
        margin-top: 50px;
    }
}