@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;800&family=Poppins:wght@100;200;300;500;700&display=swap');

.Special_alloys {
    margin-top: 30px;
    padding: 0 50px;
}
.left_col{
    text-align: left;
}
.heading_a{
    border-bottom: 1px solid #838280;
    padding: 7px 0px;
    position: relative;
    text-align: left;
    font-size: 2.4rem;
    font-weight: 600;
}
.line_h{
    position: absolute;
    height: 4px;
    width: 100px;
    background: #0A2A6A;
    bottom: -2px;
    left: 0;
}
.left_col .text_heading{
    margin-top: 30px;
    margin-bottom: 20px;
    
}
.left_col h3{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;

    
    color: #000000;
    
}
.left_col p{

    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 1.8rem;


color: #000000;
}
.Special_P{
    text-align: left;
}
.Grade_options_a{
    margin-top: 30px;
}
.Grade_options_a a{
    color: #0A2A6A;
    font-size: 1.7rem;
    font-weight: 600;
}
.grade_a_o{
    width: 300px;
    height: 40px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 4px solid;
    padding: 0 18px;
    transition: all 0.5s ease-in-out;
}
.grade_a_o:hover{
    background-color: #0A2A6A;
    color: #fff;
}
.img_a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.img_a img{
    width: 397px;
    height: 269px;
    border: 1px solid #0A2A6A;
}
.grade_inco h4{
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
}
.grade_inco h4 i{
    color: #E3B71B;
    font-size: 1.4rem;
}
.contact_us_c{
  height: 60px;
    background-color: #93a9d6ab;
    border-left: 3px solid #0A2A6A;
    position: relative;
    font-size: 1.9rem;
    margin: 35px auto 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 20px;
}
.contact_us_c i{
    color: #0A2A6A;
    font-style: normal;
    font-size: 32px;
}
.contact_us_c h5{
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
}
@media (max-width:576px) {
    .Special_alloys {
        margin-top: 30px;
        padding: 0 22px;
    }
    .img_a img {
        width: 397px;
        height: 269px;
        border: 1px solid #0A2A6A;
        margin-bottom: 20px;
    }
    .contact_us_c i {
        color: #0A2A6A;
        font-style: normal;
        font-size: 32px;
        margin-right: 10px;
    }
    .scroll_icon {
        transform: translateY(2px);
    }
}