











.About_sections{
    width: 100%;
    height: 100%;
    /* background-image: url('../../../../Constants/Images/BaseImage/aboutimg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    background-image: linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.4)),url('../../../../Constants/Images/BaseImage/aboutimg.jpg');
background-size: cover;
background-position: center;
color: #0000 !important;
background-attachment: fixed;

padding-top:30px;
padding-bottom: 70px;


}
.About_sections .button{
    width: 150px;
    height: 30px;
    background-color: #224DA1;
    color: #fff;
    border-radius: 10px;
    font-size: 1.5rem;

}
.About_sections::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: aliceblue;
}
.heading{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.headinng_text{
    font-size: 3rem;
    color: #000;
}
.headinng_text::after{
    content: '';
    height: 4px;
    width: 67px;
    background-color:#000;
}
.line_before,.line_after{
    height: 4px;
    width: 67px;
    background-color:var(--main_primary_color);
  margin: 10px;
}
.about_left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 60px;
}
.about_left h2{
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 69.5318px;
    line-height: 92px;
    
    color: rgba(0, 0, 0, 0.54);;
    
    text-shadow: 0px 3.7659px 3.7659px rgba(0, 0, 0, 0.54);
}
.about_left p{
    font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 17.777px;
line-height: 22px;
margin-top: 50px;
color:  rgba(0, 0, 0, 0.78);
text-align: justify;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.aboutContent img{
height: 400px;
width: 400px;}

.about_left p span{
    font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 48px;
line-height: 58px;

color: #224DA1;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.button-64 span a {
   text-decoration: none;
   
}


@media (max-width: 450px) {
    .button{
        width: 70px;
        height: 20px;
        font-size: 1rem;
    }
    .headinng_text{
        font-size: 2.5rem;
        color: #000;
    }
.about_left h2 {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 4.3rem;
    line-height: 63px;
    color: rgba(0, 0, 0, 0.54);;
    text-shadow: 0px 3.7659px 3.7659px rgb(0 0 0 / 54%);
}
.about_left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
}
.aboutContent img{
    height: 400px;
    width: 300px;}
    .button-64 {
        width: 80px;
    }
    .button-64 span a {
        font-size: 2rem;
        font-weight: 600;
    }
    .Home_page_heading .content {
        text-align: left;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .products_cart{
        width: 250px;
    }
}

@media (max-width: 576px) {

    .about_left p span{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 4rem;
    
    color: #224DA1;
    
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .about_left p {
        margin-top: 5px!important;
    }
    .poducts_heading {
        padding-top: 5px !important;
        text-align: center;
        margin-top: 10px!important;
    }
    .offer_section {

        margin-top: 10px !important;
        margin-bottom: 5px !important;
    }
    .contact_us_home {
        margin-top: 10px !important;
    }
    .contact_us {
        margin-top: 6px !important;
    }
    .company_status p {
        font-weight: 500;
        font-size: 1.8rem;
        color: #E5E5E5;
        text-align: justify;
        padding: 0 25px;
        color: rgba(203, 197, 197, 0.67) !important;



    }



.company_status h5 {
    color: rgba(203, 197, 197, 0.67) !important;
}
}
