.About_us_page{
    width: 100%;
    height: 100%;
    border: 1px;
    margin-top: 70px;
    
}
.about_heading{
    width: 100%;
    height: 300px;

    background-image: url('../../../../Constants/Images/BaseImage/aboutUs.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about_heading h2{
    font-weight: 700;
    font-size:4rem;
   
    /* identical to box height */
    border-bottom: 2px solid #00B274;
    
    color: #FFFFFF;
}
.about_heading h3 a{
    font-weight: 700;
    font-size: 2.3rem;
    text-decoration: none;
    color: #E3B71B;
}
.about_heading h3 {
    font-weight: 700;
    font-size: 2.3rem;

    color: #fff;
    margin-top: 10px;
    
}
.about_us_heading{
    width: 100%;
    display: flex;
    justify-content: center;
}
.about_us_heading h2{
    font-size: 4rem;
    width: fit-content;
color: rgba(0, 0, 0, 0.54);

text-shadow: 0px 3.7659px 3.7659px rgba(0, 0, 0, 0.54);

margin-top: 50px;
border-bottom: 3px solid #0A2A6A;
padding-bottom: 10px;
}
.about_content{
    margin-top: 40px;
padding: 0 70px;

}
.about_content p{
    font-size: 2rem;
    text-align: justify;
}
.about_content p span{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 4rem;
/* line-height: 92px; */

color: #0A2A6A;
}
.table_about_us{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
.table_about_us table{
    border: 1px solid #0A2A6A;
    border-radius: 20px;
}
.table_about_us table thead{
    border: 1px solid #0A2A6A;
}
.table_about_us table tbody tr{
    border: 1px solid #0A2A6A;
}

.table_about_us table tbody tr td,.table_about_us table thead tr td{
    border-right: 1px solid #0A2A6A;
    padding:20px ;
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.78);

}
.heading_why__us{
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    flex-direction:column ;
    padding:20px 6%;
    
}
.heading_certificate{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction:column ;
    padding:0 6%;
}
.heading_certificate h2{
    font-size: 2.5rem;
    padding: 10px;
    background-color: #0A2A6A;
    border-top-right-radius: 30px;
    color: #fff;
    width: fit-content;
}
.heading_why__us h2{
    font-size: 2.5rem;
    padding: 10px;
    background-color: #0A2A6A;
    border-top-right-radius: 30px;
    color: #fff;
    width: fit-content;
}
.Certificate{
    width: 400px;
    height: 500px;
}
.heading_why__us p{
    font-size: 2rem;
    border: 5px solid #0A2A6A;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: justify;
    
    margin-bottom: 20px;
    margin-top: 5px;
}
.images_aboutus {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
.images_aboutus img{
    width: 260px;
    height: 260px;
    flex-basis: 25%;
    border: 10px solid #0A2A6A;
border-radius: 10px;
}
.About_us_page .logo_modi_metals{
    width: 80%;
    height: 200px;
    margin-top: 50px;
    }
@media (max-width:576px){

    .table_about_us{
        margin-top: 50px;
        display: flex;
        justify-content: center;
        padding:0 20px;
    }
    .about_content p{
        font-size: 2.1rem;
    }
    .about_content{
        margin-top: 40px;
    padding: 0 20px;
    
    }
    .about_content p span{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 5rem;
    line-height: 92px;
    
    color: #0A2A6A;
    }
    .table_about_us table tbody tr td, .table_about_us table thead tr td {
        border-right: 1px solid #0A2A6A;
        padding: 14px;
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.78);
        font-weight: 600;
    }
    .heading_why__us {
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0 20px;
    }
    .heading_why__us h2{
        font-size: 2.1rem;
        padding: 10px;
        background-color: #0A2A6A;
        border-top-right-radius: 30px;
        color: #fff;
        margin-left: 0;
        width: fit-content;
    
    }
    .heading_why__us p{
        font-size: 2rem;
        border: 5px solid #0A2A6A;
        border-radius: 10px;
        padding: 10px 20px;
        margin-left: 0;
        margin-bottom: 20px;
        margin-top: 5px;
        
    }
    .images_aboutus img{
        margin: 0 20px;
        width: 290px;
        height: 290px;
        flex-basis: 100%;
        border: 10px solid #0A2A6A;
    border-radius: 10px;
    }
    .About_us_page .logo_modi_metals{
width: 80%;
height: 49px;
margin-top: 50px;
}
}